<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="loksabhalist" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="map1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Lok Sabha</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Import" icon="pi pi-paperclip" class="p-mr-2"
                                @click="onImportLoksabha" />
                            <Button label="Add Lok Sabha" icon="pi pi-plus" class="p-mr-2"
                                @click="addLokSabhaDialogOpen" />
                            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="filterlisting()"></Button>
                            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success" @click="filterlisting()"></Button>
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Lok Sabha Name" headerStyle="width: 63%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.map5 ? data.map5 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="State" headerStyle="width: 24%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.map4 ? data.map4 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 9%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editLokSabhaDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add loksabha dialog start here -->
    <Dialog v-model:visible="addLokSabhaStatus" :style="{ width: '800px' }" header="Add Lok Sabha" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                            v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">
                        Lok Sabha Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="loksabhaname" class="p-text-capitalize" v-model.trim="loksabhaname" required="true"
                        maxlength="50" autofocus :class="{ 'p-invalid': submitted && !loksabhaname }" />
                    <small class="p-invalid p-error" v-if="v$.loksabhaname.$error">{{
                            v$.loksabhaname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                            v$.reservationvalue.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="active_type" name="option" v-bind:value="1" v-model="statustype"
                            :checked="statustype == 1" />
                        <label for="active_type" class="p-mr-2">Active</label>
                        <RadioButton id="inactive_type" name="option" v-bind:value="0" v-model="statustype"
                            :checked="statustype == 0" />
                        <label for="inactive_type" class="p-mr-2">Inactive</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.statustype.$error">{{
                            v$.statustype.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addLokSabha" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add loksabha dialog end here -->
    <!-- edit loksabha dialog start here -->
    <Dialog v-model:visible="editLokSabhaStatus" :style="{ width: '800px' }" header="Edit Lok Sabha" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="state" :options="stateList" optionLabel="label"
                        placeholder="Select ..." :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.state.$error">{{
                            v$.state.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="loksabhaname">
                        Lok Sabha Name
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="loksabhaname" class="p-text-capitalize" v-model.trim="loksabhaname" required="true"
                        maxlength="20" autofocus :class="{ 'p-invalid': submitted && !loksabhaname }" />
                    <small class="p-invalid p-error" v-if="v$.loksabhaname.$error">{{
                            v$.loksabhaname.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="reservationvalue">
                        Reservation Seats
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="reservationvalue" v-model="reservationvalue" :options="reservationList"
                        optionLabel="label" placeholder="Select ..." :filter="false" appendTo="body"></Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.reservationvalue.$error">{{
                            v$.reservationvalue.$errors[0].$message
                    }}</small> -->
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label>Status <span class="p-invalid p-error"> *</span></label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="active_type" name="option" v-bind:value="1" v-model="statustype"
                            :checked="statustype == 1" />
                        <label for="active_type" class="p-mr-2">Active</label>
                        <RadioButton id="inactive_type" name="option" v-bind:value="0" v-model="statustype"
                            :checked="statustype == 0" />
                        <label for="inactive_type" class="p-mr-2">Inactive</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.statustype.$error">{{
                            v$.statustype.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateLokSabha" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit loksabha dialog end here -->
    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="state">State</label>
                    <MultiSelect v-model="state_fks" :options="stateList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="loksabhaname">Lok Sabha</label>
                    <MultiSelect v-model="loksabha_fks" :options="loksabhaList" optionValue="value" optionLabel="label"
                        placeholder="Select" :filter="true" class="multiselect-custom" appendTo="body"></MultiSelect>
                </div>
            </div>
        </div>
        <template #footer>
                <Button label="Reset" icon="pi pi-refresh" class="p-button-text"
                @click="reSetFilteredData()" />
                <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(state_fks, loksabha_fks)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->

    <!-- import csv dialog start here -->
    <Dialog v-model:visible="isImportLoksabha" :style="{ width: '1100px' }" header="Import Lok Sabha" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6" align="left">
                    <FileUpload mode="basic" ref="importLoksabhaFile" class="custom-upload-btn-ultima"
                                v-model="importLoksabhaFile" v-on:change="handleUploadImport()" />
                    <div style="color:red" v-if="importmsg.file">
                    {{ importmsg.file }}
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-6" align="right">
                        <label class="p-mr-2">
                            Download Sample Sheet:
                        </label>
                        <!-- TODO: Need CSV File URL here -->
                        <router-link href="https://storage.googleapis.com/meraneta_admin/import_loksabha_data.csv" target="_blank" to="#">
                            <Button icon="pi pi-download" v-tooltip.left="'Download Sample Sheet'" class="p-button-rounded">
                            </Button>
                        </router-link>
                </div>
                <div class="p-field p-col-12">
                    <h5 class="indigo-color p-m-0 p-pl-1">Note:</h5>
                    <ol style="line-height: 1.5;">
                        <li>The file name should be same as sample file name "import_loksabha_data.csv".</li>
                        <li>Do not just change any files extension from .XLS or any other extension to CSV and upload it.</li>
                        <li>You can upload enquiries in one sheet up to 5000 enquiries in one attempt.</li>
                        <li>Don't change and delete any column name in CSV sheet.</li>
                        <li>Date format must be in "dd-mm-yyyy".</li>
                        <li>Insert employee mobile number properly to allocate the enquiry to a particular employee, else enquiry will be inserted into logged-in employees Lok-neta account.</li>
                        <li>Project name, unit type & source must be the same as in the Lok-neta System.</li>
                        <li>In CSV sheet enquiry date, first name or last name, mobile or email, employee mobile, source column's are mandatory.</li>
                        <li>Submitted sheets for importing loksabha data till 10 pm will be executed on the same night and the result will be available by 7 am next day</li>
                    </ol>
                </div>
            </div>
        </div>
        <template #footer>
                <Button v-if="!importfileloader" label="Submit" icon="pi pi-check" class="p-button-text" :disabled="importmsg.file != '' ||
                            importLoksabhaFile == null ||
                            importLoksabhaFile == ''
                            "
                @click="onCSVSubmit" style="width: 90px" />
                <Button v-show="importfileloader" icon="pi pi-spin pi-spinner indigo-color"
                            class="p-button-text p-ml-auto" style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- import dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
export default {
    data() {
        return {
            v$: useValidate(),
            loksabhalist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addLokSabhaStatus: false,
            editLokSabhaStatus: false,
            state: '',
            stateList: [],
            loksabhaname: '',
            reservationvalue: '',
            reservationList: [],
            statustype: 1,
            showLoader: false,
            submitted: false,
            row_id: null,
            filterListingDialog: false,
            state_fks: [],
            loksabha_fks: [],
            loksabhaList: [],
            showFillFilter: false,
            isImportLoksabha: false,
            importfileloader: false,
            importmsg:[],
            importLoksabhaFile:'',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getloksabhalist();
        this.getStates();
        this.getReservations();
    },
    validations() {
        return {
            state: { required: helpers.withMessage('Please select state', required) },
            loksabhaname: { required: helpers.withMessage('Please enter lok sabha name', required) },
            // reservationvalue: { required: helpers.withMessage('Please select reservation', required) },
            statustype: { required: helpers.withMessage('Please select status', required) },
        };
    },
    methods: {
        onImportLoksabha() {
                this.isImportLoksabha = true;
        },
        onSubmit() {
            return;
        },
        getStates(ev) {
            this.ApiService.getStates(ev).then((data) => {
                if (data.status == 200) {
                    this.stateList = data.data;
                } else {
                    this.stateList = '';
                }
            });
        },
        getloksabha(ev) {
            this.ApiService.getloksabha(ev).then((data) => {
                if (data.status == 200) {
                    this.loksabhaList = data.data;
                } else {
                    this.loksabhaList = '';
                }
            });
        },
        getReservations(ev) {
            this.ApiService.getReservations(ev).then((data) => {
                if (data.status == 200) {
                    this.reservationList = data.data;
                } else {
                    this.reservationList = '';
                }
            });
        },
        getloksabhalist(ev) {
            this.loading = true;
            this.ApiService.getloksabhalist(ev).then((data) => {
                if (data.status == 200) {
                    this.loksabhalist = data.data.result;
                    this.totalRecords = data.data.count;
                    this.loading = false;
                } else {
                    this.loksabhalist = '';
                    this.loading = false;
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getloksabhalist({ page_no: event.page, statefilter: this.event_state, loksabhafilter: this.event_loksabha });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        handleUploadImport() {
            var file = this.$refs.importLoksabhaFile.files[0];
            let allowedExtensions = /(\.csv)$/i;
            if (!file) {
                this.importmsg["file"] = "Please upload CSV excel file.";
            } else if (!file.size > 2048) {
                this.importmsg["file"] = "File size cannot exceed more than 2MB";
            } else if (!file.name.match(allowedExtensions)) {
                this.importmsg["file"] = "Invalid file type";
            } else {
                this.importmsg["file"] = "";
                this.importLoksabhaFile = this.$refs.importLoksabhaFile.files[0];
            }
        },
        onCSVSubmit() {
            this.importfileloader = true;
            var formData = new FormData();
            formData.append("importfile", this.importLoksabhaFile);
            this.ApiService.importLoksabhaData(formData).then((items) => {
                if (items.success == true) {
                        var successMsg = items.message;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        this.isImportLoksabha = false;
                        // this.getloksabhalist();
                        this.importfileloader = false;
                    this.importLoksabhaFile = "";
                    } else {
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
            });
        },
        addLokSabhaDialogOpen() {
            this.submitted = false;
            this.addLokSabhaStatus = true;
            this.state = '';
            this.loksabhaname = '';
            this.reservationvalue = '';
            this.statustype = 1;
        },
        editLokSabhaDialogOpen(e) {
            this.getloksabhalist();
            this.editLokSabhaStatus = true;
            this.row_id = e.map1;
            let map3 = this.stateList.filter(function (item) {
                return item.value == e.map3;
            });
            if (map3.length > 0) {
                this.state = map3[0];
            }
            this.loksabhaname = e.map5;
            let map6 = this.reservationList.filter(function (item) {
                return item.value == e.map6;
            });
            if (map6.length > 0) {
                this.reservationvalue = map6[0];
            }
            this.statustype = e.map8;
        },
        addLokSabha() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['map3'] = this.state.value;
            fields['map4'] = this.state.label;
            fields['map5'] = this.loksabhaname;
            fields['map6'] = this.reservationvalue.value;
            fields['map7'] = this.reservationvalue.label;
            fields['map8'] = this.statustype;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createloksabha(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.addLokSabhaStatus = false;
                        this.showLoader = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.state = '';
                        this.loksabhaname = '';
                        this.reservationvalue = '';
                        this.statustype = 1;
                        this.getloksabhalist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        updateLokSabha() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['map1'] = this.row_id;
            fields['map3'] = this.state.value;
            fields['map4'] = this.state.label;
            fields['map5'] = this.loksabhaname;
            fields['map6'] = this.reservationvalue.value;
            fields['map7'] = this.reservationvalue.label;
            fields['map8'] = this.statustype;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createloksabha(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.editLokSabhaStatus = false;
                        this.showLoader = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getloksabhalist();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        filterlisting() {
            this.filterListingDialog = true;
            this.getloksabha();
        },
        getFilteredData(event_state, event_loksabha) {
            this.loading = true;
            this.state_fks = event_state;
            this.loksabha_fks = event_loksabha;
            this.getloksabhalist({ 
                statefilter: this.state_fks,
                loksabhafilter: this.loksabha_fks
             });
             this.showFillFilter = true;
             this.filterListingDialog = false;
        },
        reSetFilteredData(){
            this.state_fks = "";
            this.loksabha_fks = '';
            this.getloksabhalist();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        }
    },
};
</script>
<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>
